<i18n lang="yaml">
pt:
  searchPlaceholder: 'Encontrar páginas'
en:
  searchPlaceholder: 'Find pages'
</i18n>

<template>
  <TheTopbar outlined>
    <template #center-content>
      <TheTopbarSearch
        :placeholder="t('searchPlaceholder')"
        :searchable-data="searchableData"
      />
    </template>
    <template #end-content>
      <MemberTopbarGroupSwitch />
    </template>
  </TheTopbar>
</template>

<script>
import TheTopbar from '~/components/topbars/the-topbar';
import TheTopbarSearch from '~/components/topbars/the-topbar/_search';
import MemberTopbarGroupSwitch from '~/components/topbars/member-topbar/_group-switch';
import { mapState } from '~/assets/javascript/modules/vuex';
import { SearchableItemBuilder, ViewItemBuilder } from '~/assets/javascript/utils';

export default defineComponent({
  name: 'MemberTopbar',
  components: {
    TheTopbar,
    TheTopbarSearch,
    MemberTopbarGroupSwitch,
  },
  setup() {
    return {
      t: useI18n().t,
      ...mapState('viewsMenu', ['views']),
    };
  },
  computed: {
    searchableViewBuilder() {
      return new ViewItemBuilder({
        localeRoute: this.localeRoute,
        $route: this.$route,
        currentEnvironment: 'member',
      });
    },
    searchableItemBuilder() {
      return new SearchableItemBuilder(this.searchableViewBuilder);
    },
    searchableData() {
      return this.searchableItemBuilder?.buildItems(this.views);
    },
  },
});
</script>
