<i18n lang="yaml">
pt:
  alert: "Agora você pode criar uma senha para sua conta."
  configurePassword: "Configurar senha"
en:
  alert: "Now you can create a password for your account."
  configurePassword: "Configure password"
</i18n>

<template>
  <v-snackbar
    :model-value="visible"
    timeout="-1"
    :min-height="30"
    :height="30"
  >
    {{ t('alert') }}

    <a
      :href="localePath({ name: 't-tenantSlug-m-account-configuration', params: { tenantSlug: $route.params.tenantSlug, } })"
      class="text-white"
    >
      {{ t('configurePassword') }}
    </a>

    <template #actions>
      <v-btn
        icon
        size="x-small"
        :aria-label="$t('global.close')"
        @click="close"
      >
        <v-icon size="x-small">
          far fa-close
        </v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'CreatePasswordAlert',
  setup() {
    return {
      t: useI18n().t,
    };
  },
  data() {
    return {
      closed: false,
    };
  },
  computed: {
    visible() {
      return !this.closed && this.$auth.user.show_password_alert;
    },
  },
  methods: {
    close() {
      this.closed = true;
    },
  },
};

</script>
